import { GET_MY_AUTH } from "./ScanningVisitorsPage";
import StandInsights from "../Components/StandInsights";
import { useQuery } from "@apollo/client";
import React from "react";

export default function InsightPage() {
    const { data, error, loading } = useQuery(GET_MY_AUTH);
  const eventId  = data && data?.eventId;
  const standId  = data && data?.standId;
  const company  = data && data?.company;
  return (
    <React.Fragment>
      <StandInsights eventId={eventId} standId={standId} />
    </React.Fragment>
  );
}
